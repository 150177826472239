import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useFeature } from "@growthbook/growthbook-react";

const MainBanner = ({ magickLinkCta }) => {
  const feature = useFeature("campaign_manager_landing_treament")

  const [isCampaignMagicLink, setCampaignMagicLink] = useState(false);
  useEffect(() => {
    if (magickLinkCta) {
      setCampaignMagicLink(true);
    }
  });

  const cta = () => {
    return isCampaignMagicLink ? magickLinkCta : "Get Started for Free";
  };

  const featureBasedUI = (value) => {
      if (window.location.pathname.includes("startnow")) {
          return simpleCopy('Start Survey for Free Month', true);
      } else if (value === 'simple_copy') {
          return simpleCopy('Claim Free Month (worth $3k)')
      } else if (value === 'start_survey' || value === 'talk_to_sales') {
          if (window.location.pathname.includes("start")) {
              return simpleCopy('Start Survey', value === 'talk_to_sales');
          } else {
              return simpleCopy('Start Survey for Free Month', value === 'talk_to_sales');
          }
      } else {
          return control();
      }
  };

  const control = () => {
    return (
        <React.Fragment>
          <h1 className="text-white font-weight-bold h1-text">
            Qualified sales meetings
          </h1>
          <h1 className="text-white font-weight-bold h1-text">
            delivered to your inbox
          </h1>
          <h1 className="text-white font-weight-bold h1-text">
            in less than 48 hours
          </h1>
          <p className="lead text-white">
            Get high quality sales meetings with your target audience
          </p>
          <a
              className="get-started-btn btn btn-white transition-3d-hover"
              href="/account/campaign_manager/new"
          >
            {cta()}
          </a>
          <br />
          <div className="claim-text__campaings">
            <b>30 Day Free Trial. No Card Required</b>
          </div>
        </React.Fragment>
    )
  }

  const simpleCopy = (cta_text, talk_to_sales = false) => {
    return (
        <React.Fragment>
          <h1 className="text-white font-weight-bold h1-text">
              Sales Leads on Autopilot
          </h1>
          <p className="lead text-white">
            Get high quality sales meetings with your target audience
          </p>
          <div className="cta-buttons">
              <a
                  className="get-started-btn btn btn-white transition-3d-hover mt-2"
                  href="/account/campaign_manager/new"
              >
                  {cta_text}
              </a>
              {
                  talk_to_sales && (
                      <a
                          className="talk-to-sales-btn btn btn-sm ml-2 transition-3d-hover mt-2"
                          href="/contact"
                          onClick={trackEvent}
                      >
                          Talk to sales
                      </a>
                  )
              }
          </div>
          <br />
          <div className="claim-text__campaings">
            <b>No card required. Limited time offer.</b>
          </div>
        </React.Fragment>
    )
  }

    const trackEvent = () => {
        analytics.track("Clicked Talk to Sales CTA", {
            position: "top"
        });
    };

    return (
        <>
            {
                feature.value ?
                (
                    <section className="home-banner linear-gradient-campaign-manager" role="main">
                        {/* <div className="bubble one"></div>
                        <div className="bubble two"></div> */}
                        <div className="position-relative main-section-wrapper">
                            <div className="container">
                                <div className="row align-items-lg-center">
                                    <div className="col-lg-12 text-md-center mb-4">
                                        {featureBasedUI(feature.value)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ellipse"></div>
                    </section>
                ) : ""
            }
        </>
    );
};

MainBanner.propTypes = {
  magickLinkCta: PropTypes.bool.isRequired
}

export default MainBanner;
